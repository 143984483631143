import { merge } from 'lodash-es';
import { environment } from './environment';

export default merge({
    version: '39573ef8ad29f3a8f7c444f2944e549814de4cbf',

    sentry: {
        dns: 'https://05905a07aea57925f6f7a0e6e50005d6@sentry-v3.ndrive.com/5'
    },

    keycloakConfig: {
        realm: 'master',
        clientId: 'mobibus-saex',
        enableBearerInterceptor: true
    },

    roleDefaultRoute: {
        'auditor': '/history',
        'consultant': '/history'
    }
}, environment);
